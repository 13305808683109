import is from "@sindresorhus/is";
import { type Metadata, type RpcError } from "grpc-web";
import i18n, { changeLanguage } from "i18next";
import log from "loglevel";
import ow from "ow";
import userTrackingClient from "../../clients/UserTracking";
import {
    type UserTrackingClient,
    type AccountDetails as UserTrackingAccountDetails,
} from "../../clients/UserTracking/UserTrackingClient";
import questUIStrings from "../../constants/strings";
import { LanguageKind } from "../../grpc/account_common_pb";
import {
    AuthenticationRequest,
    CreateAccountRequest,
    GoogleAuthenticationDetails,
    ThirdPartyAuthenticationRequest,
    UpdateAccountRequest,
    UpdatePasswordRequest,
    DeactivateAccountRequest,
    VerifyEmailRequest,
    ForgotPasswordRequest,
    ResetPasswordRequest,
    UpdateEmailRequest,
    CaptureUserEmailRequest,
} from "../../grpc/account_pb";
import type { AccountsClient } from "../../grpc/AccountServiceClientPb";
import GrpcHelpers from "../../grpc/GrpcHelpers";
import GrpcStatusCodes from "../../grpc/GrpcStatusCodes";
import { toGrpcStringValue } from "../../grpc/mappers/stringMapper";
import { getLanguageByLanguageCode } from "../../utils/languageUtils";
import cookieManager, { type CookieManager } from "../cookieManager";
import accountsClient from "./accountsClient";
import AccountServiceError from "./AccountServiceError";
import ErrorCode from "./ErrorCode";
import { mapGrpcLanguageKindToLanguage, mapLanguageToGrpcLanguageKind } from "./mappers/mappers";
import type {
    ThirdPartyAuthenticationResponse,
    UpdateAccountDetails,
    AuthenticationResponse,
    GetAccountResponse,
    CreateAccountResponse,
    UpdatePasswordResponse,
    UpdateEmailResponse,
} from "./types";
import(/* webpackPrefetch: true */ "../../clients/QuestService/QuestServiceClient");

const MINIMUM_PASSWORD_LENGTH = 8;

class AccountService {
    accountsClient: AccountsClient;
    private cookieManager: CookieManager;
    private userTrackingClient: UserTrackingClient;

    constructor(
        accountsClient: AccountsClient,
        cookieManager: CookieManager,
        userTrackingClient: UserTrackingClient
    ) {
        if (is.nullOrUndefined(accountsClient)) {
            throw new Error("accountsClient is null or undefined.");
        }

        if (is.nullOrUndefined(cookieManager)) {
            throw new Error("cookieManager is null or undefined.");
        }

        if (is.nullOrUndefined(userTrackingClient)) {
            throw new Error("userTrackingClient is null or undefined.");
        }

        this.accountsClient = accountsClient;
        this.cookieManager = cookieManager;
        this.userTrackingClient = userTrackingClient;
    }

    async getAccountAsync() {
        const request = GrpcHelpers.buildEmpty();

        let response;
        try {
            response = await this.accountsClient.getAccount(request, this._getMetadata(true));
        } catch (error) {
            if ((error as RpcError).code === GrpcStatusCodes.statusCodes.unauthenticated) {
                const message = "Unauthenticated";
                log.debug(message);
                throw new AccountServiceError(message, ErrorCode.Unauthenticated);
            } else {
                const message = `Error getting account: ${error}`;
                log.debug(message);
                throw new AccountServiceError(message, ErrorCode.GetAccountError);
            }
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        const data = response.toObject();
        if (!is.nonEmptyObject(data)) {
            const message = "Response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        const authenticationDetails: GetAccountResponse = {
            id: data.id,
            email: data.email?.value ?? null,
            firstName: data.firstName?.value ?? null,
            middleName: data.middleName?.value ?? null,
            lastName: data.lastName?.value ?? null,
            birthDate: data.birthDate ?? null,
            phoneNumber: data.phoneNumber?.value ?? null,
            isAdmin: data.isAdmin,
            preferredLanguageCode:
                mapGrpcLanguageKindToLanguage(data.preferredLanguage)?.languageCode ?? null,
            linkedThirdPartyAccounts: data.linkedThirdPartyAccountsList,
            isEmailVerified: data.isEmailVerified?.value ?? false,
            peopleClerkUserId: data.peopleClerkUserId?.value ?? null,
            isActive: data.isActive,
        };

        return authenticationDetails;
    }

    async isLoggedInAsync() {
        // Checks that an auth cookie exists
        const storedAccountDetails = this.cookieManager.getAccountDetails();

        // TODO: MVP-2718: Set user fullName properties once AccountService supports a full user data response
        if (
            !is.nonEmptyObject(storedAccountDetails) ||
            !is.nonEmptyStringAndNotWhitespace(storedAccountDetails.email) ||
            !is.nonEmptyStringAndNotWhitespace(storedAccountDetails.authToken) ||
            !is.number(storedAccountDetails.accountId)
        ) {
            return false;
        }

        const accountDetails = await this.getAccountAsync();

        const isLoggedIn =
            storedAccountDetails.accountId === accountDetails.id &&
            is.nonEmptyStringAndNotWhitespace(accountDetails.email) &&
            // This will ignore casing but not accents (e.g. "a" is different from "ä").
            storedAccountDetails.email.localeCompare(accountDetails.email, undefined, {
                sensitivity: "accent",
            }) === 0;

        return isLoggedIn;
    }

    async loginAsync(email: string, password: string) {
        ow(email, ow.string.nonEmpty);
        ow(password, ow.string.nonEmpty);

        const request = new AuthenticationRequest();
        request.setEmail(GrpcHelpers.buildStringValue(email));
        request.setPassword(GrpcHelpers.buildStringValue(password));

        let response;
        try {
            response = await this.accountsClient.authenticate(request, this._getMetadata());
        } catch (error) {
            log.debug("Error calling login:", error);
            throw new AccountServiceError(
                "Error logging in: error calling accountService.authenticate",
                ErrorCode.AuthenticationError
            );
        }

        if (is.nullOrUndefined(response)) {
            throw new AccountServiceError(
                "Authenticate response is null or undefined",
                ErrorCode.InvalidResponse
            );
        }

        const data = response.toObject();

        if (
            !(
                is.nonEmptyObject(data) &&
                is.number(data.accountId) &&
                is.nonEmptyString(data.authToken) &&
                is.boolean(data.isAdmin)
            )
        ) {
            const errorMessage = "Error calling login: response data is invalid";
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.InvalidResponse);
        }

        const authenticationDetails: AuthenticationResponse = {
            accountId: data.accountId,
            isAdmin: data.isAdmin,
            authToken: data.authToken,
            email: data.email?.value ?? null,
            firstName: data.firstName?.value ?? null,
            lastName: data.lastName?.value ?? null,
            preferredLanguageCode:
                mapGrpcLanguageKindToLanguage(data.preferredLanguage)?.languageCode ?? null,
            isEmailVerified: data.isEmailVerified?.value ?? false,
            isThirdPartyAuth: false,
            peopleClerkUserId: data.peopleClerkUserId?.value ?? null,
        };

        try {
            this.cookieManager.updateAccountDetails({
                accountId: authenticationDetails.accountId,
                authToken: authenticationDetails.authToken,
                email: authenticationDetails.email,
                firstName: authenticationDetails.firstName,
                lastName: authenticationDetails.lastName,
                preferredLanguageCode: authenticationDetails.preferredLanguageCode,
                isEmailVerified: authenticationDetails.isEmailVerified,
                isThirdPartyAuth: authenticationDetails.isThirdPartyAuth,
            });
        } catch (error) {
            const errorMessage = `Error updating cookie on login: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.CookieError);
        }

        this._trackUserDetails({
            accountId: authenticationDetails.accountId,
            email: authenticationDetails.email,
            firstName: authenticationDetails.firstName,
            lastName: authenticationDetails.lastName,
            preferredLanguage: authenticationDetails.preferredLanguageCode,
        });

        // Track login event
        try {
            this.userTrackingClient.trackLogin();
        } catch (error) {
            // Don't throw an error since the app should still be functional
            log.debug(`Error tracking login: ${error}`);
        }

        try {
            if (!is.nullOrUndefined(authenticationDetails.preferredLanguageCode)) {
                await changeLanguage(authenticationDetails.preferredLanguageCode);
            }
        } catch (e) {
            log.error(e);
        }

        return authenticationDetails;
    }

    async signupAsync(
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        preferredLanguageCode?: string | null,
        referrer?: string | null
    ) {
        ow(firstName, ow.string.nonEmpty);
        ow(lastName, ow.string.nonEmpty);
        ow(email, ow.string.nonEmpty);
        ow(password, ow.string.minLength(MINIMUM_PASSWORD_LENGTH));

        const languageCode = is.nonEmptyString(preferredLanguageCode)
            ? preferredLanguageCode
            : i18n.resolvedLanguage;
        const language = getLanguageByLanguageCode(languageCode);

        if (is.nullOrUndefined(language)) {
            const message = `Language code ${languageCode} is invalid`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidLanguage);
        }

        const request = new CreateAccountRequest();
        request.setFirstName(GrpcHelpers.buildStringValue(firstName));
        request.setLastName(GrpcHelpers.buildStringValue(lastName));
        request.setEmail(GrpcHelpers.buildStringValue(email));
        request.setPassword(GrpcHelpers.buildStringValue(password));
        request.setPreferredLanguage(
            mapLanguageToGrpcLanguageKind(language) ?? LanguageKind.ENGLISH
        );

        let response;
        try {
            response = await this.accountsClient.createAccount(request, this._getMetadata());
        } catch (error) {
            if ((error as RpcError).code === GrpcStatusCodes.statusCodes.alreadyExists) {
                const message = "Error signing up: existing email";
                log.debug(message);
                throw new AccountServiceError(message, ErrorCode.AlreadyExistsError);
            } else {
                const message = `Error signing up: ${error}`;
                log.debug(message);
                throw new AccountServiceError(message, ErrorCode.CreateAccountError);
            }
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        const data = response.toObject();
        if (!is.nonEmptyObject(data)) {
            const message = "Response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        const authenticationDetails: CreateAccountResponse = {
            loginId: data.loginId?.value ?? null,
            accountId: data.accountId,
            authToken: data.authToken,
            email: data.email?.value ?? null,
            firstName: data.firstName?.value ?? null,
            lastName: data.lastName?.value ?? null,
            preferredLanguageCode:
                mapGrpcLanguageKindToLanguage(data.preferredLanguage)?.languageCode ?? null,
            isAdmin: data.isAdmin,
            isEmailVerified: data.isEmailVerified?.value ?? false,
            isThirdPartyAuth: false,
        };

        // Update auth cookie
        try {
            this.cookieManager.updateAccountDetails({
                accountId: authenticationDetails.accountId,
                authToken: authenticationDetails.authToken,
                email: authenticationDetails.email,
                firstName: authenticationDetails.firstName,
                lastName: authenticationDetails.lastName,
                preferredLanguageCode: authenticationDetails.preferredLanguageCode,
                isThirdPartyAuth: authenticationDetails.isThirdPartyAuth,
                isEmailVerified: authenticationDetails.isEmailVerified,
            });
        } catch (error) {
            const message = `Error updating cookie on sign up: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.CookieError);
        }

        // Track the referrer
        if (is.nonEmptyString(referrer)) {
            await this._trackReferralAsync(referrer);
        }

        this._trackUserDetails({
            accountId: authenticationDetails.accountId,
            email: authenticationDetails.email,
            firstName: authenticationDetails.firstName,
            lastName: authenticationDetails.lastName,
            preferredLanguage: authenticationDetails.preferredLanguageCode,
        });

        // Track signup event
        try {
            this.userTrackingClient.trackSignup();
        } catch (error) {
            // Don't throw an error since the app should still be functional
            log.debug(`Error tracking signup: ${error}`);
        }

        return authenticationDetails;
    }

    async thirdPartyAuthenticateAsync(
        idToken: string,
        preferredLanguageCode?: string | null,
        referrer?: string | null
    ) {
        ow(idToken, ow.string.nonEmpty);

        const languageCode = is.nonEmptyString(preferredLanguageCode)
            ? preferredLanguageCode
            : i18n.resolvedLanguage;
        const language = getLanguageByLanguageCode(languageCode);

        if (is.nullOrUndefined(language)) {
            const message = `Language code ${languageCode} is invalid`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidLanguage);
        }

        const googleAuthDetails = new GoogleAuthenticationDetails();
        googleAuthDetails.setIdToken(GrpcHelpers.buildStringValue(idToken));

        const request = new ThirdPartyAuthenticationRequest();
        request.setGoogleAuthenticationDetails(googleAuthDetails);
        request.setPreferredLanguage(
            mapLanguageToGrpcLanguageKind(language) ?? LanguageKind.ENGLISH
        );

        let response;
        try {
            response = await this.accountsClient.thirdPartyAuthenticate(
                request,
                this._getMetadata()
            );
        } catch (error) {
            if ((error as RpcError).code === GrpcStatusCodes.statusCodes.alreadyExists) {
                const message = "Error logging in with third party authentication: existing email";
                log.debug(message);
                throw new AccountServiceError(
                    message,
                    ErrorCode.ThirdPartyAuthenticationEmailExistsError
                );
            } else {
                const message = `Error authenticating with a third-party: ${error}`;
                log.debug(message);
                throw new AccountServiceError(message, ErrorCode.ThirdPartyAuthenticationError);
            }
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        const data = response.toObject();
        if (!is.nonEmptyObject(data)) {
            const message = "Response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        const authenticationDetails: ThirdPartyAuthenticationResponse = {
            accountId: data.accountId,
            authToken: data.authToken,
            email: data.email?.value ?? null,
            firstName: data.firstName?.value ?? null,
            lastName: data.lastName?.value ?? null,
            preferredLanguageCode:
                mapGrpcLanguageKindToLanguage(data.preferredLanguage)?.languageCode ?? null,
            linkedThirdPartyAccounts: data.linkedThirdPartyAccountsList,
            isThirdPartyAuth: true,
            isEmailVerified: data.isEmailVerified?.value ?? false,
            wasAccountCreated: data.wasAccountCreated,
        };

        // Update auth cookie
        try {
            this.cookieManager.updateAccountDetails({
                accountId: authenticationDetails.accountId,
                authToken: authenticationDetails.authToken,
                email: authenticationDetails.email,
                firstName: authenticationDetails.firstName,
                lastName: authenticationDetails.lastName,
                preferredLanguageCode: authenticationDetails.preferredLanguageCode,
                isThirdPartyAuth: authenticationDetails.isThirdPartyAuth,
                isEmailVerified: authenticationDetails.isEmailVerified,
            });
        } catch (error) {
            const message = `Error updating cookie on authentication with a third-party: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.CookieError);
        }

        // Track the referrer for account creation
        if (data.wasAccountCreated && is.nonEmptyString(referrer)) {
            await this._trackReferralAsync(referrer);
        }

        this._trackUserDetails({
            accountId: authenticationDetails.accountId,
            email: authenticationDetails.email,
            firstName: authenticationDetails.firstName,
            lastName: authenticationDetails.lastName,
            preferredLanguage: authenticationDetails.preferredLanguageCode,
        });

        // Track third-party authentication event
        try {
            if (authenticationDetails.wasAccountCreated) {
                this.userTrackingClient.trackGoogleSignup();
            } else {
                this.userTrackingClient.trackGoogleLogin();
            }
        } catch (error) {
            // Don't throw an error since the app should still be functional
            log.debug(`Error tracking third-party authentication: ${error}`);
        }

        return authenticationDetails;
    }

    logout() {
        this._clearAccount();

        try {
            this.userTrackingClient.trackLogout();
            this.userTrackingClient.reset();
        } catch (error) {
            log.debug(`Error handling user tracking: ${error}`);
        }

        // Set language back to browser's preferred language
        try {
            changeLanguage();
        } catch (e) {
            log.error(`Error changing language: ${e}`);
        }
    }

    async updateAccountAsync(newPartialAccountDetailsObject: UpdateAccountDetails) {
        const accountDetails = this.cookieManager.getAccountDetails();
        const updatedAccount: UpdateAccountDetails = {
            ...accountDetails,
            ...newPartialAccountDetailsObject,
        };

        ow(updatedAccount, ow.object);
        ow(updatedAccount.firstName, ow.string);
        ow(updatedAccount.lastName, ow.string);

        const languageCode = updatedAccount.preferredLanguageCode;
        const language = getLanguageByLanguageCode(languageCode);

        if (is.nullOrUndefined(language)) {
            const message = `Language code ${languageCode} is invalid`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidLanguage);
        }
        const languageKind = mapLanguageToGrpcLanguageKind(language);

        const request = new UpdateAccountRequest();
        request.setFirstName(GrpcHelpers.buildStringValue(updatedAccount.firstName));
        request.setLastName(GrpcHelpers.buildStringValue(updatedAccount.lastName));
        request.setPreferredLanguage(languageKind ?? LanguageKind.ENGLISH);

        let response;
        try {
            response = await this.accountsClient.updateAccount(request, this._getMetadata(true));
        } catch (error) {
            const message = `Error updating the account: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.UpdateAccountError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "UpdateAccount response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        try {
            this.cookieManager.updateAccountDetails({
                firstName: updatedAccount.firstName,
                lastName: updatedAccount.lastName,
                preferredLanguageCode: updatedAccount.preferredLanguageCode,
            });
        } catch (error) {
            const errorMessage = `Error updating cookie in updateAccount: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.CookieError);
        }

        return updatedAccount;
    }

    async updatePasswordAsync(password: string, newPassword: string) {
        ow(password, ow.string.nonEmpty);
        ow(newPassword, ow.string.minLength(MINIMUM_PASSWORD_LENGTH));

        if (!window.navigator.onLine) {
            const message = `Error calling updatePassword: Browser not online.`;
            throw new AccountServiceError(message, ErrorCode.OfflineBrowser);
        }

        const currentEmail = this.cookieManager.getAccountDetails()?.email;
        ow(currentEmail, ow.string.nonEmpty);

        const request = new UpdatePasswordRequest();
        request.setEmail(GrpcHelpers.buildStringValue(currentEmail));
        request.setPassword(GrpcHelpers.buildStringValue(password));
        request.setNewPassword(GrpcHelpers.buildStringValue(newPassword));

        let response;
        try {
            response = await this.accountsClient.updatePassword(request, this._getMetadata(true));
        } catch (error) {
            const message = `Error calling updatePassword: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.UpdatePasswordError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Error calling updatePassword: response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.UpdatePasswordError);
        }

        const data = response.toObject();
        if (
            !(
                is.number(data?.accountId) &&
                is.nonEmptyString(data?.authToken) &&
                is.boolean(data?.isAdmin)
            )
        ) {
            const message = "Error calling updatePassword: response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        const authenticationDetails: UpdatePasswordResponse = {
            accountId: data.accountId,
            authToken: data.authToken,
            isAdmin: data.isAdmin,
        };

        try {
            this.cookieManager.updateAccountDetails({
                accountId: authenticationDetails.accountId,
                authToken: authenticationDetails.authToken,
            });
        } catch (error) {
            const errorMessage = `Error updating cookie on updatePassword: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.CookieError);
        }

        return authenticationDetails;
    }

    async deactivateAccountAsync(email: string, password: string) {
        ow(email, ow.string.nonEmpty);
        ow(password, ow.string.nonEmpty);

        const request = new DeactivateAccountRequest();
        request.setEmail(GrpcHelpers.buildStringValue(email));
        request.setPassword(GrpcHelpers.buildStringValue(password));

        let response;
        try {
            response = await this.accountsClient.deactivateAccount(
                request,
                this._getMetadata(true)
            );
        } catch (error) {
            const errorMessage = `Error calling deactivateAccount: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.DeactivateAccountError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }
    }

    isEmailVerified() {
        const accountDetails = this.cookieManager.getAccountDetails();
        if (is.null_(accountDetails)) {
            log.error("Error validating whether email is verified, Auth cookie is null.");
            return false;
        }

        const isEmailVerified = accountDetails.isEmailVerified ?? false;
        log.debug(`current email verify status: ${isEmailVerified ? "Verified" : "Unverified"}`);

        return isEmailVerified;
    }

    async sendEmailVerificationAsync() {
        if (this.isEmailVerified()) {
            const message = "Email is already verified";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.EmailAlreadyVerified);
        }

        let response;
        try {
            response = await this.accountsClient.sendEmailVerification(
                GrpcHelpers.buildEmpty(),
                this._getMetadata(true)
            );
        } catch (error) {
            const errorMessage = `Error calling sendEmailVerification: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.SendEmailVerificationError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }
    }

    async verifyEmailAsync(emailVerificationToken: string) {
        ow(emailVerificationToken, ow.string.nonEmpty);

        if (this.isEmailVerified()) {
            const message = "Email is already verified";
            log.debug(message);
            /*
             * If the email is already verified, we return to the caller,
             * as if the call succeeded, as there's no need to verify the
             * user's email address.
             */
            return;
        }

        const request = new VerifyEmailRequest();
        request.setEmailVerificationToken(GrpcHelpers.buildStringValue(emailVerificationToken));

        let response;
        try {
            response = await this.accountsClient.verifyEmail(request, this._getMetadata(true));
        } catch (error) {
            if ((error as RpcError).code === GrpcStatusCodes.statusCodes.notFound) {
                const message = "Not found";
                log.debug(message);
                throw new AccountServiceError(message, ErrorCode.NotFound);
            } else if ((error as RpcError).code === GrpcStatusCodes.statusCodes.permissionDenied) {
                const message = "Permission denied";
                log.debug(message);
                throw new AccountServiceError(message, ErrorCode.PermissionDenied);
            } else {
                const message = `Error calling verifyEmail: ${error}`;
                log.debug(message);
                throw new AccountServiceError(message, ErrorCode.VerifyEmailError);
            }
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        // Update auth cookie
        try {
            this.cookieManager.updateAccountDetails({
                isEmailVerified: true,
            });
        } catch (error) {
            const message = `Error updating cookie on email verification: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.CookieError);
        }
    }

    async forgotPasswordAsync(email: string) {
        ow(email, ow.string);

        const request = new ForgotPasswordRequest();
        request.setEmail(GrpcHelpers.buildStringValue(email));

        let response;
        try {
            response = await this.accountsClient.forgotPassword(request, this._getMetadata(false));
        } catch (error) {
            const errorMessage = `Error calling forgotPassword: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.ForgotPasswordError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }
    }

    async resetPasswordAsync(resetToken: string, password: string) {
        ow(resetToken, ow.string);
        ow(password, ow.string);

        const request = new ResetPasswordRequest();
        request.setResetToken(GrpcHelpers.buildStringValue(resetToken));
        request.setPassword(GrpcHelpers.buildStringValue(password));

        let response;
        try {
            response = await this.accountsClient.resetPassword(request, this._getMetadata(false));
        } catch (error) {
            const errorMessage = `Error calling resetPassword: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.ResetPasswordError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }
    }

    async updateEmailAsync(password: string, newEmail: string) {
        ow(password, ow.string.nonEmpty);
        ow(newEmail, ow.string.nonEmpty);

        const currentEmail = this.cookieManager.getAccountDetails()?.email;
        ow(currentEmail, ow.string.nonEmpty);

        const request = new UpdateEmailRequest();
        request.setEmail(GrpcHelpers.buildStringValue(currentEmail));
        request.setPassword(GrpcHelpers.buildStringValue(password));
        request.setNewEmail(GrpcHelpers.buildStringValue(newEmail));

        let response;
        try {
            response = await this.accountsClient.updateEmail(request, this._getMetadata(true));
        } catch (error) {
            const message = `Error calling updateEmail: ${error}`;
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.UpdateEmailError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "UpdateEmail response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        const data = response.toObject();
        if (
            !(
                is.number(data?.accountId) &&
                is.nonEmptyString(data?.authToken) &&
                is.boolean(data?.isAdmin)
            )
        ) {
            const message = "Error calling updateEmail: response data is invalid";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }

        const authenticationDetails: UpdateEmailResponse = {
            authToken: data.authToken,
            accountId: data.accountId,
            isAdmin: data.isAdmin,
        };

        try {
            this.cookieManager.updateAccountDetails({
                email: newEmail,
                authToken: authenticationDetails.authToken,
                accountId: authenticationDetails.accountId,
                isEmailVerified: false,
            });
        } catch (error) {
            const errorMessage = `Error updating account details cookie on updateEmail: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.CookieError);
        }

        return authenticationDetails;
    }

    async captureUserEmailAsync(email: string) {
        ow(email, ow.string.nonEmpty);

        const request = new CaptureUserEmailRequest();
        request.setEmail(toGrpcStringValue(email));

        let response;
        try {
            response = await this.accountsClient.captureUserEmail(
                request,
                this._getMetadata(false)
            );
        } catch (error) {
            const errorMessage = `Error calling captureUserEmail: ${error}`;
            log.debug(errorMessage);
            throw new AccountServiceError(errorMessage, ErrorCode.CaptureUserEmailError);
        }

        if (is.nullOrUndefined(response)) {
            const message = "Response is null or undefined";
            log.debug(message);
            throw new AccountServiceError(message, ErrorCode.InvalidResponse);
        }
    }

    _trackUserDetails(userDetails: UserTrackingAccountDetails) {
        try {
            this.userTrackingClient.identifyUser({
                accountId: userDetails.accountId,
                email: userDetails.email,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                preferredLanguage: userDetails.preferredLanguage,
            });
        } catch (error) {
            // Don't throw an error since the app should still be functional
            log.debug(`Error identifying the user: ${error}`);
        }
    }

    _clearAccount() {
        try {
            this.cookieManager.removeAccountDetails();
            localStorage.clear();
        } catch (error) {
            log.debug(`Error clearing account details: ${error}`);
            throw new AccountServiceError(
                "Error clearing account details",
                ErrorCode.ClearAccountError
            );
        }
    }

    _getMetadata(includeAuthHeaders = false) {
        ow(includeAuthHeaders, ow.boolean);

        const metadata: Metadata = {};
        if (includeAuthHeaders) {
            const accountDetails = this.cookieManager.getAccountDetails();
            if (is.nonEmptyString(accountDetails?.authToken)) {
                metadata[
                    questUIStrings.httpHeaders.authorization
                ] = `${questUIStrings.bearer} ${accountDetails.authToken}`;
            } else {
                /*
                 * This should not happen if authentication is handled correctly, but
                 * if it does, return the metadata without the auth headers and let the call fail.
                 */
                log.debug("Error getting auth token. Auth token is null or undefined");
            }
        }
        return metadata;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async _trackReferralAsync(referrer: string) {
        try {
            const QuestServiceClient = (
                await import("../../clients/QuestService/QuestServiceClient")
            ).default;
            await QuestServiceClient.trackReferralAsync(referrer);
        } catch (error) {
            log.debug(`Error tracking the referral from the referrer ${referrer}:`, error);
        }
    }
}

export default new AccountService(accountsClient, cookieManager, userTrackingClient);
